import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { FaSpinner } from "react-icons/fa";

import { SettingsProvider } from './context/SettingsContext';
import {API_URL} from './components/Constants';

import Home from './components/Home';
import NotFound from './components/NotFound';
import Lot from './components/Lot';
import Add from './components/Add';
import Search from './components/Search';

const App = () => {
  const [authStatus, setAuthStatus] = useState(null);
  const [settings, setSettings] = useState(null);

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')
  const [isSubmitting, setIsSubmitting] = useState('')

  useEffect(() => {
    document.title = 'Add Valet Parker | ValParx';

    const itemStr = localStorage.getItem('authKey');
    if (itemStr) {
      const item = JSON.parse(itemStr);
      checkToken(item.value)
    } 
  }, []);

  /**
   * 
   * @param {str}  key 
   */
  const checkToken = (key) => {
    var myHeaders = new Headers();
    //myHeaders.append("vp-authorization", AUTH_TOKEN);

    var formdata = new FormData();
    formdata.append("key", key);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + '/gettoken', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.code === 200) {
          //need to store the user_id in localstorage for usage
          const settings = {
            apiUrl: API_URL,
            userToken: data.token,
            //authToken: AUTH_TOKEN
          };
          setSettings(settings);
          setAuthStatus(true);
          
        } else {
          setAuthStatus(false);
        }
      });
  }

  const closeMessage = () => {
    setMessage('')
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true)

    if (!username) {
      setIsSubmitting(false)
      setMessage('Username is required. Please check your values and try again.')
      return;
    }

    if (!password) {
      setIsSubmitting(false)
      setMessage('Password is required. Please check your values and try again.')
      return;
    }

    //fetch
    var formdata = new FormData();
    formdata.append("email", username);
    formdata.append("pass", password);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "checklogin", requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log(result)
        setIsSubmitting(false)

        if (result.code == 200) {
          const settings = {
            userToken: result.token
          };
          setSettings(settings);
          
          const item = {
            value: result.key
          };
          localStorage.setItem('authKey', JSON.stringify(item));

          setAuthStatus(true);
        } else {
          setMessage(result.message)
        }
      })
      .catch(error => {
        //console.log('error', error)
        setMessage('Sorry, there was an error: ' + error + ' Please try again.')
        setIsSubmitting(false)
      });
  }

  return (
    <SettingsProvider value={settings}>
      <Router>
        {authStatus ? (
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/lot/:id" element={<Lot />} />
            <Route exact path="/parker/:parker" element={<Home />} />
            <Route exact path="/add/:id" element={<Add />} />
            <Route exact path="/add/:id/:userId" element={<Add />} />
            <Route exact path="/add/edit/:transactionId" element={<Add />} />
            <Route exact path="/search" element={<Search />} />

            {/* Fallback 404 Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        ) : (
          <div className='min-h-screen flex-col flex items-center justify-center bg-primary py-12 px-4 sm:px-6 lg:px-8'>
            <div className="max-w-md w-full space-y-8">
              <h1 className='mt-6 text-center text-3xl font-bold text-white'>
                Please login below:
              </h1>
              
              <form onSubmit={handleSubmit}>
                <div className='mb-4 w-full'>
                  <input
                    id="name"
                    type="text"
                    placeholder='Username'
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                    onBlur={(event) => setUsername(event.target.value)}
                    className="text-2xl bg-white border border-solid border-accent w-full p-2 rounded-md"
                  />
                </div>

                <div className='mb-10 w-full'>
                  <input
                    id="password"
                    type="password"
                    value={password}
                    placeholder='Password'
                    onChange={(event) => setPassword(event.target.value)}
                    onBlur={(event) => setPassword(event.target.value)}
                    className="text-2xl bg-white border border-solid border-accent w-full p-2 rounded-md" 
                  />
                </div>

                {message && (
                  <div class="mx-auto box-border w-[365px] border bg-white p-4 border-solid border-red-500">
                    <div class="flex items-center justify-between">
                      <span class="text-[#64748B] font-semibold">Error:</span>
                      <div class="cursor-pointer border rounded-[4px]" onClick={closeMessage}>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-[#64748B]" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </div>
                    </div>

                    <div class="mt-6">
                      <div class="">{message}</div>
                    </div>
                  </div>
                )}

                {isSubmitting ? (
                  <div className="group relative w-full flex justify-center mt-10 py-2 px-4 border font-bold border-accent border-solid text-md rounded-md text-white hover:text-accent bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 transition items-center">
                    <FaSpinner className="text-white fa-spin" />
                  </div>
                ) : (
                  <input type="submit" value="Submit" className="group mt-10 relative w-full flex justify-center py-2 px-4 border font-bold border-accent border-solid text-md rounded-md text-white hover:text-accent bg-accent hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 transition items-center cursor-pointer" />
                )} 
              </form>
            </div>
          </div>
        )}
      </Router>
    </SettingsProvider>
  );
}

export default App;
