import React, {useEffect, useState} from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';

import { useSettings } from '../context/SettingsContext'
import { Nav } from './partials/Nav';
import { API_URL } from './Constants';
import ParkingList from './partials/ParkingList';
import Loading from './partials/Loading';

const Lot = () => {
  const { id } = useParams();
  const settings = useSettings();
  const token = settings.userToken
  const navigate = useNavigate();

  const [gettingData, setGettingData] = useState(true)
  const [data, setData] = useState()
  const [lotName, setLotName] = useState()
  const [lotData, setLotData] = useState()
  const [showClosed, setShowClosed] = useState(false)

  /**
   * 
   */
  useEffect(() => {
    document.title = 'Lot | ValParx'
  }, [])

  /**
   * 
   */
  useEffect(() => {
    if (token) {
      getData()

      // Set up the interval to fetch data every 5 minute
      const intervalId = setInterval(getData, (60 * 1000 * 5));

      // Clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [token, id, showClosed])

  /**
   * 
   */
  const refreshData = () => {
    getData()
  }

  /**
   * 
   */
  const editTransation = (transactionId) => {
    //console.log('editTransation ' + id)
    navigate('/add/edit/'+transactionId);
  }

  /**
   * 
   */
  const getData = () => {
    setGettingData(true)

    //console.log('getting data')
    var myHeaders = new Headers();
    myHeaders.append("vp-session-token", token);

    var formdata = new FormData();
    formdata.append("id", id);

    if (showClosed) {
      formdata.append("closed", "1");
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "getopen", requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log(result)
        if (result.code == 200) {
          setGettingData(false)
          setData(result.parking)

          setLotName(result.lot)

          const lotData = {
            in:  result.in,
            out: result.out,
            now: result.now
          }
          setLotData(lotData)
        } else {
          alert(result.message)
          setGettingData(false)
        }
      })
      .catch(error => {
        //console.log('error', error)
        alert('Unexpected error: '+ error)
        setGettingData(false)
      });
  }

  const toggleClosed = (x) => {
    console.log(x);
    if (x === 1) {
      setShowClosed(true)
    } else {
      setShowClosed(false)
    }
  }

  return (
    <div className='min-h-screen'>
      <Nav lotData={lotData} back={true} />

      <div className='pt-32 lg:px-10 px-5 pb-24'>
        {gettingData ? (
          <Loading />
        ) : (
          <div className='p-3'>
            <p className='text-center mb-4 text-2xl font-bold'>Selected Lot: {lotName}</p>

            <div className="py-3 max-w-lg mx-auto">
              <div className="bg-gray-200 rounded-lg mb-10 p-1">
                <div className="relative flex items-center">
                  <div id="segment-selection" className={`absolute left-0 inset-y-0 w-1/2 flex bg-white transition-all ease-in-out duration-200 transform rounded-md shadow translate-x-0 ${showClosed ? 'translate-x-full' : 'translate-x-0'}`}></div>

                  <a className='relative flex-1 flex text-sm font-semibold capitalize items-center justify-center cursor-pointer m-px p-px' onClick={() => toggleClosed(0)}>Open</a>
                  <a className='relative flex-1 flex text-sm font-semibold capitalize items-center justify-center cursor-pointer m-px p-px' onClick={() => toggleClosed(1)}>Closed</a>
                </div>
              </div>
            </div>

            <div className="py-3 max-w-3xl mx-auto">
              {data && data.length > 0 ? (
                <ParkingList data={data} updateData={refreshData} token={token} editTransaction={editTransation} />
              ) : (
                <p className='text-center text-xl'>No vehicles listed yet.</p>
              )}
            </div>
          </div>
        )}
      </div>

      <div className='flex bg-primary w-full p-2 lg:p-4 fixed bottom-0'>
        <Link className='flex items-center justify-center w-full py-3 px-4 bg-darkgrey rounded-3xl max-w-[600px] mx-auto' to={"/add/"+id}>
          <div className='text-center text-white text-xl font-bold uppercase'>Add</div>
        </Link>
      </div>
    </div>
  )
}

export default Lot