import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaPowerOff, FaArrowLeft, FaSearch } from "react-icons/fa";

export const Nav = ({children = 'ValParx', lotData, back }) => {
  const navigate = useNavigate();
   
  const logout = () => {
    localStorage.removeItem('authKey')
    window.location.reload();
  }

  const goBack = () => {
    //window.history.back()
    console.log('here');
    navigate(-1);
  }

  const goSearch = () => {
    navigate('/search');
  }
 
  return (
    <nav className="w-full flex flex-col justify-between items-center fixed z-50">
      <div className="w-full mx-auto items-center flex justify-between lg:flex-nowrap bg-primary flex-wrap lg:px-6 px-4">
        <div>
          <FaSearch onClick={goSearch} className='text-white' />
        </div>

        <Link to={"/"}><h1 className='mb-2 text-white text-lg'>{children}</h1></Link>
 
        <div>
          <FaPowerOff onClick={logout} className='text-white' />
        </div>
      </div>

      {lotData && (
        <div className='py-3 px-7 bg-darkgrey flex flex-row justify-between w-full'>
          <p className='text-white mb-0'>In: {lotData.in}</p>
          <p className='text-white mb-0'>Out: {lotData.out}</p>
          <p className='text-white mb-0'>Now: {lotData.now}</p>
        </div>
      )}

      {back && (
        <div className='w-full lg:px-6 px-4 py-5'>
          <FaArrowLeft onClick={goBack} className='text-black' />
        </div>
      )}
    </nav>
  )
 }