import React, {useEffect, useState} from 'react'
import { Link, useParams } from 'react-router-dom';
import { FaArrowRight, FaSpinner } from 'react-icons/fa';

import { useSettings } from '../context/SettingsContext'
import { Nav } from './partials/Nav';

import {API_URL} from './Constants';

const Home = () => {
  const { parker } = useParams();
  const settings = useSettings();

  const [data, setData] = useState()

  useEffect(() => {
    document.title = 'Home | ValParx'
    let token = settings.userToken;

    if (token) {
      getData(token)
    }
  }, []);

  const getData = (token) => {
    //console.log('getting data')
    var myHeaders = new Headers();
    myHeaders.append("vp-session-token", token);

    var formdata = new FormData();

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "getlots", requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log(result)
        if (result.code == 200) {
          setData(result.lots)
        } else {
          alert(result.message)
        }
      })
      .catch(error => {
        console.log('error', error)
      });
  }

  return (
    <div className='min-h-screen'>
      <Nav />
      <div className='pt-20 lg:px-10 px-5'>
        {parker && (
          <div className='mx-auto max-w-2xl w-full'>
            <p className='text-center font-bold'>Select the lot the monthly parker is parking at.</p>
          </div>
        )}

        {data ? (
          (data.length > 0 ? (
            <ul id='sites' className={`w-full max-w-2xl mx-auto`}>
              {data.map((lot, index) => (
                <li key={index} className="mb-2">
                  <Link to={parker ? "/add/"+lot.id+"/"+parker : "/lot/"+lot.id} className=" uppercase py-1 font-bold flex justify-between items-center bg-gray-100 rounded-lg p-2">
                    <span className='overflow-x-clip text-gray-900'>{lot.name}</span>
                    <FaArrowRight className='mr-2 text-sm text-accent ' />
                  </Link>
                </li>
              ))}
            </ul>
           ) : (
            <p className='text-center font-medium'>No active lots found. <br></br>You can start adding some with the button above.</p>
           ))
        ) : (
          <p className='text-blueGray-500 items-center flex text-center justify-center'>
            <span>Loading Lots...</span>
            <FaSpinner className='text-sm ml-2 fa-spin' />
          </p>
        )}
      </div>
    </div>
  )
}

export default Home