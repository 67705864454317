import React, { useEffect, useState } from 'react';
import { FaArrowRight, FaSpinner } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import {API_URL} from '../Constants';

const ParkingList = ({ data, updateData, token, editTransaction }) => {
  const [selectedParkingId, setSelectedParkingId] = useState(null);
  const [closingId, setClosingId] = useState(null)
  const [showClose, setShowClose] = useState(false)
  const [closingDriver, setClosingDriver] = useState('')
  const [showTextBox, setShowTextBox] = useState(false)
  const [text, setText] = useState('')
  const [sendingMessage, setSendingMessage] = useState(false)

  const toggleVisibility = (id) => {
    if (selectedParkingId === id) {
      setSelectedParkingId(null);  // Hide the details if already visible
    } else {
      setSelectedParkingId(id);  // Show the details
    }
  };

  const statusDisplay = (status) => {
    if (status == 1) {
      return (
        <div className='text-right text-sm md:text-lg text-green-500 font-bold'>Open</div>
      )
    }

    if (status == 0) {
      return (
        <div className='text-right text-sm md:text-lg font-bold'>Closed</div>
      )
    }

    if (status == 2) {
      return (
        <div className='text-right text-sm md:text-lg text-yellow-800 font-bold'>Paid</div>
      )
    }

    if (status == 3) {
      return (
        <div className='text-right text-sm md:text-lg text-red-500 font-bold'>Ready For Pickup</div>
      )
    }
  }

  const updateButton = (id) => {
    editTransaction(id)
    //console.log('hey');
  }

  const handleChange = (event) => {
    setText(event.target.value); // Updates the state variable with the current value of the textarea
  };

  const sendMessage = (id) => {
    setSendingMessage(true)

    if (!text) {
      setSendingMessage(false)
      alert('A message is required. Please try again.');
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("vp-session-token", token);

    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("text", text)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "sendmessage", requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log(result)
        if (result.code == 400) {
          alert(result.message)
        } else {
          setSendingMessage(false)
          updateData()
          setShowClose(false)
        }
      })
      .catch(error => console.log('error', error));
  }

  const closeParking = (id) => {
    setClosingId(id)

    if (!closingDriver) {
      setClosingId(null)
      alert('Driver name is required. Please try again.');
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("vp-session-token", token);

    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("closing_driver", closingDriver)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(API_URL + "closetransaction", requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log(result)
        if (result.code == 400) {
          alert(result.message)
        } else {
          setClosingId(null)
          updateData()
          setShowClose(false)
        }
      })
      .catch(error => console.log('error', error));
  }

  return (
    <div>
      {data.map((parkingItem) => (
        <div key={parkingItem.id} className='mb-4 rounded-lg bg-gray-200'>
          
          {/* First Container */}
          <div onClick={() => toggleVisibility(parkingItem.id)} className='flex flex-row items-center justify-between p-4 '>
            <div className='text-left text-sm md:text-lg'>{parkingItem.key_tag} {parkingItem.id}</div>
            <div>{statusDisplay(parkingItem.status)}</div>
          </div>
          
          {/* Second Container */}
          {selectedParkingId === parkingItem.id && (
            <div className='p-4 bg-gray-300 rounded-b-lg'>
              <div className='mb-5'>
                <div className='md:text-lg text-base font-bold text-center'>
                  <div>Space Number: {parkingItem.stall_number || "Not set"}</div>
                  <div>Driver: {parkingItem.driver || "Not set"}</div>
                  {parkingItem.closing_driver && (
                    <div>Closing Driver: {parkingItem.closing_driver}</div>
                  )}
                </div>
              </div>

              <div className='md:flex lg:flex-row lg:justify-between mb-5'>
                {/* Customer Info */}
                <div className='md:text-md text-base'>
                  <div>Name:  {parkingItem.customer_name || 'N/A'}</div>
                  <div>Phone: {parkingItem.customer_phone || 'N/A'}</div>
                  <div>Email: {parkingItem.customer_email || 'N/A'}</div>
                </div>
                
                {/* Vehicle Info */}
                <div className='lg:text-right md:text-md text-base'>
                  <div>Color: {parkingItem.vehicle_color || 'N/A'}</div>
                  <div>Plate: {parkingItem.vehicle_plate || 'N/A'}</div>
                  <div>Make:  {parkingItem.vehicle_make || 'N/A'}</div>
                </div>
              </div>

              <div className='md:flex lg:flex-row lg:justify-between mb-5'>
                <div className='md:text-md text-base'>
                  <div>Checkin: {parkingItem.time_created}</div>
                </div>
                
                <div className='lg:text-right md:text-md text-base'>
                  <div>Checkout: {parkingItem.time_closed}</div>
                </div>
              </div>

              {/* Actions */}
              {parkingItem.customer_phone && parkingItem.status != 0 && (
                <>
                  <div className='flex flex-row items-center justify-center w-full gap-2 mb-5'>
                    <div className='flex items-center justify-center py-2 px-5 bg-darkgrey rounded-xl cursor-pointer' onClick={() => setShowClose(!showClose)}>
                      <div className='text-center text-white text-sm font-bold uppercase'>Close</div>
                    </div>

                    <div className='flex items-center justify-center py-2 px-5 bg-darkgrey rounded-xl cursor-pointer' onClick={() => updateButton(parkingItem.id)}>
                      <div className='text-center text-white text-sm font-bold uppercase'>Edit</div>
                    </div>
                  </div>

                  <div className='flex flex-row items-center justify-center w-full gap-2'>
                    <div className='flex items-center justify-center py-2 px-5 bg-darkgrey rounded-xl cursor-pointer' onClick={() => setShowTextBox(!showTextBox)}>
                      <div className='text-center text-white text-sm font-bold uppercase'>Send Customer Message</div>
                    </div>
                  </div>
                </>
              )}

              {showClose && (
                <div className='mt-5 max-w-[450px] mx-auto md:flex gap-3'>
                  <input
                    id="closing_driver"
                    type='text'
                    className='border border-solid border-gray-500 rounded p-1 lg:text-xl text-base md:text-sm w-full'
                    onChange={(event) => setClosingDriver(event.target.value)}
                    onBlur={(event) => setClosingDriver(event.target.value)}
                    editable="true"
                    value={closingDriver}
                    autoCorrect="false"
                    placeholder='Your name'
                  />

                  {closingId === parkingItem.id ? (
                    <div className='flex items-center justify-center py-2 px-5 bg-darkgrey rounded-xl'>
                      <FaSpinner className='text-sm ml-2 fa-spin text-white' />
                    </div>
                  ) : (
                    <a className='flex w-[300px] items-center justify-center py-2 px-5 bg-darkgrey rounded-xl cursor-pointer' onClick={() => closeParking(parkingItem.id)}>
                      <div className='text-center text-white text-sm font-bold uppercase'>Close Transaction</div>
                    </a>
                  )}
                </div>
              )}

              {showTextBox && (
                <div className='mt-5 max-w-[450px] mx-auto text-center'>
                  <textarea
                    value={text} // Binds the textarea value to the state variable
                    onChange={handleChange} // Calls handleChange on every keystroke to update the state
                    placeholder="Enter the message you need to send to the customer here." // Placeholder text for the textarea
                    style={{ width: '100%', height: '150px' }} // Sets the size of the textarea
                    className='p-2'
                  />

                  {sendingMessage ? (
                    <div className='flex items-center justify-center py-2 px-5 bg-darkgrey rounded-xl w-full'>
                      <FaSpinner className='text-sm ml-2 fa-spin text-white' />
                    </div>
                  ) : (
                    <a className='flex items-center justify-center py-2 px-5 bg-darkgrey rounded-xl cursor-pointer w-full' onClick={() => sendMessage(parkingItem.id)}>
                      <div className='text-center text-white text-sm font-bold uppercase'>Send Message</div>
                    </a>
                  )}
                </div>
              )}
            </div>
          )}

        </div>
      ))}
    </div>
  );
};

export default ParkingList;
