import { FaSpinner } from 'react-icons/fa';

const Loading = () => {
  return(
    <div className='h-full bg-white flex flex-row w-full justify-center items-center'>
      <FaSpinner className='text-lg ml-2 fa-spin' />
      <div className='text-xl text-center mt-5'>Loading, please wait...</div>
    </div>
  )
}

export default Loading