import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import { ThemeProvider } from "@material-tailwind/react";

import './assets/css/variables.css';
import './assets/css/index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);


